<template>
  <div id="app">
    <router-view></router-view>
    <bottom-footer/>
  </div>
</template>

<script>
import BottomFooter from 'views/footer/BottomFooter'

export default {
  name: "App",
  components:{
    BottomFooter,
  }
}
</script>

<style>
  @import "./assets/css/base.css";

</style>
