// 这个文件是首页里面所有的数据请求，放在首页的话代码量太多，这样首页只管怎么展示数据，不用管怎么拿数据
import {request} from "./axios"

// 首页导航栏的商品类别的数据请求
export function getCategory(){ return request({ url: '/categorys/', }) }

// 首页轮播图的数据请求
export function getHomeBanner(){ return request({ url: '/banners/' }) }

// 首页产品列表数据请求
export function getHomeProducts(){ return request({ url: '/products/', }) }

// 首页中合作单位数据请求
export function getCoCompany(){ return request({ url: '/brands/' }) }

// 获取底部导航数据信息
export function getFooters(){ return request({ url: '/footers/' }) }


// export function getHomeGoods(type, page){
//   return request({
//     url: '/home/data',   
//     params: {
//       type,
//       page
//     }
//   })
// }