import Vue from 'vue'
import VueRouter from 'vue-router'

const Home = () => import('views/home/Home.vue')
const About = () => import('views/about/About.vue')
const Technology = () => import('views/products/Technology.vue')
const VRAR = () => import('views/products/VRAR.vue')
const Finite = () => import('views/products/Finite.vue')
const Digital = () => import('views/products/Digital.vue')
const EHS = () => import('views/products/EHS.vue')
const Inspection = () => import('views/products/Inspection.vue')
const Consult = () => import('views/products/Consult.vue')
const LimitedSpace = () => import('views/products/LimitedSpace.vue')
const NetWorkSecurity = () => import('views/businesses/NetworkSecurity.vue')
const Industry = () => import('views/businesses/Industry.vue')
const RIskanalyze = () => import('views/businesses/RIskanalyze.vue')
const EmulationCompute = () => import('views/businesses/EmulationCompute.vue')
const InformationTechnologyService = () => import('views/businesses/InformationTechnologyService.vue')
const BlockChain = () => import('views/businesses/BlockChain.vue')

Vue.use(VueRouter)

const routes = [
 // {
   // path: '/',
   // redirect: '/home'
 // },
  {
    path: '/products/',
    redirect: '/'
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about',
    name: 'About',
    component: About,
  },

  // 写单纯页面
  {
    path: '/products/technology',
    name: 'Technology',
    component: Technology,
  },
  {
    path: '/products/vr-ar',
    name: 'VRAR',
    component: VRAR,
  },
  {
    path: '/products/finite',
    name: 'Finite',
    component: Finite,
  },
  {
    path: '/products/digital',
    name: 'Digital',
    component: Digital,
  },
  {
    path: '/products/ehs',
    name: 'EHS',
    component: EHS,
  },
  {
    path: '/products/inspection',
    name: 'Inspection',
    component: Inspection,
  },
  {
    path: '/products/consult',
    name: 'Consult',
    component: Consult,
  },
  {
    path: '/products/limited-space',
    name: 'LimitedSpace',
    component: LimitedSpace,
  },

  //zmh modify 网页改版  20230709
  {
    path: '/products/network-security',
    name: 'NetWorkSecurity',
    component: NetWorkSecurity,
  },
  {
    path: '/products/industry',
    name: 'Industry',
    component: Industry,
  },
  {
    path: '/products/risk-analyze',
    name: 'risk-analyze',
    component: RIskanalyze,
  },
  {
    path: '/products/emulation-compute',
    name: 'emulation-compute',
    component: EmulationCompute,
  },
  {
    path: '/products/infoemation-technology',
    name: 'infoemation-technology',
    component: InformationTechnologyService,
  },
  {
    path: '/products/block-chain',
    name: 'block-chain',
    component: BlockChain,
  },
  
]
  
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
