<template>
  <div class="footer">
    <div class="footer-main">
      <div class="footer-img">
        <img src="~assets/img/footer/professional-long.jpg" alt="">
        <img src="~assets/img/footer/concentration-long.jpg" alt="">
        <img src="~assets/img/footer/honesty-long.jpg" alt="">
        <img src="~assets/img/footer/inspiration-long.jpg" alt="">
      </div>
    </div>
    <div class="footers">
      <ul v-for="item in footers">
        <li  class="li-footers" v-if="item.is_tab">
          <router-link :to="'/home/list/' + item.id">
              <div class="item-name">{{item.name}}</div>
              <div v-if="item.name == '扫码联系'">
                <img src="~assets/img/head/wechat_code.jpg">
              </div>
          </router-link>
          <ul>
            <li v-for="list in item.sub_cat">
              <router-link :to="'/home/list/' + list.id">
                  <div class="list-name">{{list.name}}</div>
              </router-link>
            </li>
          </ul>
        </li>
      </ul> 
    </div>
    <div class="text">电话咨询： 180-6752-3007 | 意见反馈： <a href="mailto:Service@foresea.tech">Service@foresea.tech</a></div>
    <div class="footer-text">© 2018 宁波前湾信息技术有限公司 | 保留所有权利 | <a href="https://beian.miit.gov.cn/">浙ICP备18035622号</a> </div>
    </div>
  </div>
</template>

<script>
import { getFooters } from "network/home"
export default {
  name: 'BottomFooter',
  data(){
    return {
      footers: [],
    }
  },
  created(){
    getFooters().then( res => {
      // console.log(res)
      this.footers = res
    })
  }
}
</script>

<style scoped>
li{ list-style: none; }

.footer{
  border-top: solid 5px #008ae2;
  background: #fff;
}

/**zmh modify 修改底部背景色 */
.footer-main{
  background: #51a2d2 none repeat scroll 0 0;
  height: 60px;
}

.footer-img {
  display: flex;
  margin: 0 auto;
  width: 1300px;
}

.footer-img img{
  /* flex: 1; */
  height: 50px;
  line-height: 60px;
  width: 180px;
  margin: 5px 0 0 120px;
}

.footers{
  width: 1000px;
  margin: 0 auto;
  display: flex;
  border-left: dotted 2px #eee;
  margin-bottom: 10px;
  text-align: center;
}

.footers .li-footers{
  list-style: none;
  width: 150px;
  height:140px;
  border-right: dotted 2px #eee;
  position: relative;
}

.footers .li-footers img{
  width: 120px;
}


.footers .item-name{
  width: 140px;
  height: 25px;
  line-height: 25px;
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
}

.footers .list-name{
  margin: 10px 0 0 0;
  color: #008ae2 ;
  font-size: 14px;
}

.text{
  color: black;
  margin: 15px 0 0 0;
  text-align:center;
  font-size: 15px;
}

.text a{
  color: #008ae2;
}

.footer-text{
  text-align: center;
  font-family: "宋体";
  font-size: 13px;
  padding: 15px 0;
  /*  color: #008ae2 ;  */
  margin-bottom: 20px;
}
</style>
