import axios from 'axios'

export function request(config) {
    // 1.创建axios的实例
    const instance = axios.create({
	    baseURL: 'http://121.196.55.218:8000/api/',
        timeout: 55000
    });

    // 2.配置请求和响应拦截(axios的拦截器)
    // 2.1 请求拦截的作用
    instance.interceptors.request.use(config => {

		return config
		}, err => {

		return err
		})


        // 2.2 响应拦截
		instance.interceptors.response.use(res => {
			// console.log('来到了response拦截success中');
			return res.data
		}, err => {
			console.log('来到了response拦截failure中');
            console.log(err);
        })
    // 3.发送真正的网络请求
    return instance(config)
}
